import React, { HTMLInputTypeAttribute, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';

import StyledFormControl from './StyledFormControl';

export interface TextInputProps {
  label?: string;
  labelStyle?: React.CSSProperties;
  focusColor?: string;
  value?: string | null;
  onChange?: (value: string) => void;
  error?: string | null;
  type?: HTMLInputTypeAttribute;
  ref?: React.MutableRefObject<HTMLInputElement>;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}
const TextInput = React.forwardRef(
  (
    {
      type = 'text',
      label = 'unset',
      labelStyle = {},
      focusColor = '',
      value = null,
      onChange,
      error = undefined,
      onKeyUp,
      ...props
    }: TextInputProps,
    ref: React.RefObject<HTMLInputElement>,
  ) => {
    const [inputValue, setInputValue] = useState<string>(value ?? '');
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [wasPasted, setWasPasted] = useState<boolean>(false);

    const handleChange = (event: { target: { value: string } }) => {
      const { value } = event.target;

      setInputValue((previousValue) => {
        // allow validation of auto filled values even if focused
        if (
          value.length > previousValue.length + 1 ||
          value.length < previousValue.length - 1
        ) {
          setWasPasted(true);
        } else {
          setWasPasted(false);
        }
        return value;
      });
      setIsChanged(true);
    };

    useEffect(() => {
      if (onChange) {
        onChange(inputValue);
      }
    }, [inputValue, onChange]);

    const showError =
      ((isChanged && !isFocused) || wasPasted) && Boolean(error);

    return (
      <StyledFormControl
        showError={showError}
        label={label}
        labelPlacement="top"
        labelStyle={{ fontSize: '14px', ...labelStyle }}
        control={
          <TextField
            data-testid={label}
            name={label}
            size="small"
            fullWidth={true}
            margin="dense"
            error={showError}
            onKeyUp={onKeyUp}
            inputRef={ref}
            sx={{
              fieldset: {
                background: showError ? 'rgb(255,0,0,0.1)' : 'undefined',
              },
              '& .MuiInputBase-input': {
                height: '23px',
                fontSize: '14px',
                fontWeight: '500',
                color: '#232323',
              },
              '& .MuiFormHelperText-contained': {
                color: 'red',
                fontSize: '10px',
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: focusColor,
                },
              },
            }}
            type={type}
            variant="outlined"
            value={inputValue}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            helperText={showError ? error : ' '}
            {...props}
            autoComplete={type}
          />
        }
      />
    );
  },
);

export default TextInput;
