import { isValidPhoneNumber } from 'libphonenumber-js';

export type validationFunction = (
  value: string,
  fieldName?: string,
) => string | undefined;

export const notEmptyStringValidation = (value: string, fieldName?: string) => {
  if (!(value && value.length > 0)) {
    return `${fieldName ? fieldName : 'field'} is required`;
  }
  return undefined;
};

const regex =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const emailRegexValidation = (value: string) => {
  if (value.includes(' ') || !regex.test(value)) {
    return `value is not a valid email`;
  }
  return undefined;
};

export const phoneValidation = (value: string) => {
  if (!isValidPhoneNumber(value, 'US')) {
    return `value is not a valid phone number`;
  }
  return undefined;
};

export const auth0passwordValidation = (value: string) => {
  const upperCase = /[A-Z]/;
  const lowerCase = /[a-z]/;
  const number = /[0-9]/;
  const special = /[!@#$%^&*]/;
  let message = '';
  let error = false;

  if (value.length < 8) {
    error = true;
    message += `password must be at least 8 characters. `;
  }
  if (
    value.search(upperCase) < 0 ||
    value.search(lowerCase) < 0 ||
    value.search(number) < 0 ||
    value.search(special) < 0
  ) {
    error = true;
    message += `Passwords must contain one of each
       uppercase letter, 
       lowercase Letter, 
       number
       special character(!@#$%^&*)`;
  }

  return error ? message : undefined;
};

export const zipCodeValidation = (value: string) => {
  const regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  if (!regex.test(value)) {
    return `value is not a valid zip code`;
  }
  return undefined;
};
